<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
    <el-form-item>
      <el-select v-model="ruleForm.cooperationId" placeholder="请选择">
        <el-option
          v-for="item in daili"
          :key="item.id"
          :label="item.name"
          :disabled="item.activation_code_num0<=0"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
      <el-form-item v-if="ruleForm.cooperationId">
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      ruleForm: {
        userId: 0,
        cooperationId:''
      },
      daili: [],
    };
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
    this.getDaili();
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      this.getDaili();
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
  methods: {
    getDaili() {
      console.log("===getDaili====");
      operate.getDailiAll({ status: 1 }).then((response) => {
        this.daili = response.data;
      });
    },
    getInfo(id) {
      console.log("===getInfo====");
      operate.getUserInfo(id).then((response) => {
        let data=response.data
        this.ruleForm.userId = data.id;
      });
    },
    submitForm() {
      operate.editTourist(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
  },
};
</script>