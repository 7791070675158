<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="city" label="城市"> </el-table-column>
      <el-table-column prop="year" label="年"> </el-table-column>
      <el-table-column prop="month" label="月"> </el-table-column>
      <el-table-column prop="day" label="日"> </el-table-column>
      <el-table-column prop="hour" label="小时"> </el-table-column>
      <el-table-column prop="minute" label="分钟"> </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <div v-if="scope.row.sex==1">男</div> <div v-if="scope.row.sex==0">女</div>
        </template>
      </el-table-column>
      <el-table-column label="是否阴历">
        <template slot-scope="scope">
          <div v-if="scope.row.islunar==0">阳历</div> <div v-if="scope.row.islunar==1">阴历</div>
        </template>
      </el-table-column>
      <el-table-column label="是否太阳时">
        <template slot-scope="scope">
          <div v-if="scope.row.istaiyang==0">否</div> <div v-if="scope.row.istaiyang==1">是</div>
        </template>
      </el-table-column>
      <el-table-column label="是否是闰月">
        <template slot-scope="scope">
         <div v-if="scope.row.islunarmonth==1">是</div>
         <div v-if="scope.row.islunarmonth==2">否</div>
        </template>
      </el-table-column>
      <el-table-column label="测试时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import updatePage from "./info.vue";
export default {
  components: {
    updatePage
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible:false,
      addPageVisible:false,
      editId:0,
      tableData: [],
      crumb:['排盘解密','列表']
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    deleteRow(index, rows) {
      let _info=this.tableData[index]
      operate.deletePaipan(_info.id).then((response)=>{
      if(response.code=='0000'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
    searchput() {
     this.getList(1);
    },
    getKeyword() {},
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId)
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    // edit(index) {
    //   let id = this.tableData[index].id;
    //   let path = this.$route.path + "/info/" + id;
    //   this.$router.push(path);
    // },
    getList(p) {
      let data={}
      if(this.keyword.length>0){
        data={ pageIndex: p, name: this.keyword }
      }else{
        data={ pageIndex: p}
      }
      operate.getPaipanList(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
  },
  mounted() {
    operate.getPaipanList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>