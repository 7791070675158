<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="上传头像">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情顶图">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-remove="uploadTopRemove"
          :on-success="uploadTopCallBack"
          :headers="headers"
        >
          <img
            v-if="ruleForm.topImage"
            :src="'/api' + ruleForm.topImage"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label="0">男</el-radio>
          <el-radio :label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="等级">
        <el-radio-group v-model="ruleForm.level">
          <el-radio :label="1">资深导师</el-radio>
          <el-radio :label="2">金牌导师</el-radio>
          <el-radio :label="3">王牌导师</el-radio>
          <el-radio :label="4">高级导师</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="擅长内容">
        <el-input v-model="ruleForm.skill"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.shotDesc"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="详细简介">
        <quill-editor
          class="editor"
          v-model="ruleForm.description"
          ref="descriptionEditor"
          :options="editorOption"
          @change="onDescriptionChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="适合人群">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.crowd"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="课程安排">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.course"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="收费说明">
        <quill-editor
          class="editor"
          v-model="ruleForm.chargeDesc"
          ref="chargeDescEditor"
          :options="editorOption"
          @change="onChargeDescChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="非会员价" prop="charge">
        <el-input v-model="ruleForm.charge"></el-input>
      </el-form-item>
      <el-form-item label="会员价" prop="memberCharge">
        <el-input v-model="ruleForm.memberCharge"></el-input>
      </el-form-item>
      <el-form-item label="基础费用" prop="baseCharge">
        <el-input v-model="ruleForm.baseCharge"></el-input>
      </el-form-item>
      <el-form-item label="基础时间" prop="baseChargeTime">
        <el-input v-model="ruleForm.baseChargeTime"></el-input>
      </el-form-item>
      <el-form-item label="是否上线">
        <el-radio-group v-model="ruleForm.status">
        <el-radio :label="0">下线</el-radio>
        <el-radio :label="1">上线</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-upload
        class="rich-upload"
        action="/api/fileInfo/upload"
        :multiple="false"
        :show-file-list="false"
        :on-success="richUploadSuccess"
        :headers="headers"
        style="height: 10px"
      ></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        topImage:"",
        sex: "",
        level: "",
        skill: "",
        shotDesc: "",
        description: "",
        crowd: "",
        course: "",
        charge: "",
        memberCharge: "",
        chargeDesc: "",
        baseCharge:"",
        baseChargeTime:"",
        id: 0,
      },
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
    };
  },
  methods: {
    onChargeDescChange({ quill, html, text }) {
      this.chargeDesc = html;
    },
    onDescriptionChange({ quill, html, text }) {
      this.description = html;
    },
    submitForm() {
      operate.editTeacher(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
      console.log(this.ruleForm);
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadTopCallBack(response, file, fileList) {
      console.log('response',response)
      if (response.code == "0000") {
        this.ruleForm.topImage = response.data;
      }
    },
    uploadTopRemove(file, fileList) {
      this.ruleForm.topImage = "";
    },
    //详细描述图片上传
    richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.chargeDescEditor.quill;
        let _image = response.data;
        if (_image) {
          if (!quill.getSelection() || quill.getSelection() == null) {
            quill = this.$refs.descriptionEditor.quill;
          }
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image",  '/api'+_image);
          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
    getInfo(id) {
      operate.getTeacherInfo(id).then((response) => {
        this.ruleForm = response.data;
      });
    },
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>