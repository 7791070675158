<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-select v-model="payStatus" placeholder="请选择支付状态">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="isMember" placeholder="请选择用户身份">
          <el-option
            v-for="item in members"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="start_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择开始时间"
        >
        </el-date-picker>
        <span> - </span>
        <el-date-picker
          class="input-width"
          v-model="end_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择结束时间"
        >
        </el-date-picker>
        <!-- <el-input
          placeholder="请输入充值金额"
          clearable
          size="small"
          style="width: 240px"
          v-model="chongMoneyStart"
        ></el-input>
        <el-input
          placeholder="请输入充值金额"
          clearable
          size="small"
          style="width: 240px"
          v-model="chongMoneyEnd"
        ></el-input> -->
        <el-input
          placeholder="请输入姓名"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;color: #909399;font-size: 14px;margin-bottom: 10px;">
      <div style="margin-right: 10px;">总订单：{{ total}}</div>
      <div style="margin-right: 10px;">已支付：{{ payCount}}</div>
      <div style="margin-right: 10px;">未支付：{{ nopayCount}}</div>
      <div>已取消{{ cancelCount}}</div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="outTradeNo" label="订单号"> </el-table-column>
      <el-table-column prop="money" label="金额"> </el-table-column>
      <el-table-column prop="distributionMoney" label="分销金额"> </el-table-column>
      <el-table-column prop="points" label="获得易豆"> </el-table-column>
      <el-table-column prop="memberPoints" label="奖励易豆"> </el-table-column>
      <el-table-column label="支付状态">
        <template slot-scope="scope">
         <div v-if="scope.row.payStatus==10">未支付</div>
         <div v-if="scope.row.payStatus==11">已支付</div>
         <div v-if="scope.row.payStatus==20">取消</div>
        </template>
      </el-table-column>
      <el-table-column label="下单时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      start_date: "",
      end_date: "",
      keyword: "",
      payStatus:'',
      isMember:'',
      chongMoneyEnd:'',
      chongMoneyStart:'',
      total: 0,
      payCount:0,
      nopayCount:0,
      cancelCount:0,
      tableData: [],
      members:[
      {
          value: -2,
          label: "不限",
        },
        {
          value: 1,
          label: "会员",
        },
        {
          value: 2,
          label: "游客",
        },
      ],
      options: [
      {
          value: -2,
          label: "不限状态",
        },
        {
          value: 10,
          label: "未支付",
        },
        {
          value: 11,
          label: "已支付",
        },
        {
          value: 20,
          label: "已取消",
        },
      ],
      crumb:['订单','列表']
    };
  },
  methods: {
    deleteRow(index, rows) {
      let _info=this.tableData[index]
      operate.deleteorder(_info.id).then((response)=>{
      if(response.code=='0000'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
    searchput() {
      this.getList(1)
    },
    getKeyword() {},
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/edit/" + id;
      this.$router.push(path);
    },
    getList(p) {
      console.log('p----',p)
      let data={}
      if(this.keyword.length>0){
        data={ pageIndex: p, outTradeNo : this.keyword }
      }else{
        data={ pageIndex: p}
      }
      if(this.payStatus!=''&&this.payStatus!=-2){
        data.payStatus=this.payStatus
      }
      if(this.isMember!=''&&this.isMember!=-2){
        data.isMember=this.isMember
      }
      if (this.start_date) {
        let createStartAt = new Date(this.start_date).valueOf();
        data.createStartAt = createStartAt;
      }
      if (this.end_date) {
        let createEndAt = new Date(this.end_date).valueOf();
        data.createEndAt = createEndAt;
      }
      if (this.chongMoneyStart) {
        data.chongMoneyStart = this.chongMoneyStart;
      }
      if (this.chongMoneyEnd) {
        data.chongMoneyEnd = this.chongMoneyEnd;
      }
      operate.getOrderList(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
  },
  mounted() {
    operate.getOrderList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    operate.getOrderList({payStatus:11}).then((response) => {
      this.payCount = response.total;
    });
    operate.getOrderList({payStatus:10}).then((response) => {
      this.nopayCount = response.total;
    });
    operate.getOrderList({payStatus:20}).then((response) => {
      this.cancelCount = response.total;
    });
  },
};
</script>