<template>
  <div class="right-content">
    <el-form label-width="100px" class="demo-ruleForm">
      <el-form-item label="选择代理">
        <el-select v-model="cooperationId" filterable placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      user_id: 0,
      cooperationId: '',
      options: [
      ],
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
    this.getDaili();
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: '数据获取中',
      });
      this.id = newval;
      this.getInfo(newval);
      this.getDaili();
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    }
  },
  methods: {
    getDaili(){
      let that=this
      that.options=[];
      operate.getDailiAll({}).then((response) => {
        let list = response.data;
        list.forEach(function(info,index){
          console.log('---',info)
          if(info.status==1&&info.userDTO){
          that.options.push({
            value:info.id,
            label:info.name+'（'+info.userDTO.nickname+'）'
          })
        }
        })
        
         console.log(that.options)
      });
    },
    getInfo(id) {
      operate.getUserInfo(id).then((response) => {
        let info = response.data;
        this.user_id = info.id;
      });
    },
    submitForm() {
      operate.changeDaili({ userId: this.user_id, cooperationId: this.cooperationId }, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          this.$emit('closeDaili');
        }
      });
    },
  },
  mounted() {
    // let param = this.$route.params;
    // let id = param["id"];
    // operate.getCooperationInfo(id).then((response) => {
    //   this.ruleForm = response.data;
    // });
  },
};
</script>