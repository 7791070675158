<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
   <el-form-item label="减少数量" prop="decreaseNum">
        <el-input v-model="ruleForm.decreaseNum" type="number"></el-input>
      </el-form-item>
     
      <el-form-item v-if="ruleForm.status==1">
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        status:0,
        decreaseNum:'',
        id: 0,
      },
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
  },
  watch:{
      id(newval,oldval){
        let loadingInstance=Loading.service({
          text: '数据获取中',
        });
        this.id = newval;
        this.getInfo(newval);
        setTimeout(() => {
          loadingInstance.close();
        }, 2000);
      }
  },
  methods: {
    getInfo(id) {
      operate.getCooperationInfo(id).then((response) => {
      this.ruleForm.id = response.data.id;
      this.ruleForm.status = response.data.status;
    });
    },
    submitForm() {
      operate.decreaseActivationCodeNum(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
              this.$emit('closeDispatch');
        }
      });
    },
  },
  mounted() {
    // let param = this.$route.params;
    // let id = param["id"];
    // operate.getCooperationInfo(id).then((response) => {
    //   this.ruleForm = response.data;
    // });
  },
};
</script>