<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
     
      <el-form-item label="等级" prop="addNum">
      
        <el-select v-model="ruleForm.activationCodePackageId" placeholder="请选择">
          <el-option
            v-for="item in codes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      codes:[],
      ruleForm: {
        activationCodePackageId:'',
        id: 0,
      },
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
  },
  watch:{
      id(newval,oldval){
        let loadingInstance=Loading.service({
          text: '数据获取中',
        });
        this.id = newval;
        this.getInfo(newval);
        setTimeout(() => {
          loadingInstance.close();
        }, 2000);
      }
  },
  methods: {
    getInfo(id) {
      operate.getCooperationInfo(id).then((response) => {
      this.ruleForm = response.data;
    });
    },
    submitForm() {
      operate.changeCooperationLevel(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
              this.$emit('closeDispatch');
        }
      });
    },
  },
  mounted() {
    operate.allCodePackageList().then((res) => {
      this.codes=res.data
    });
  },
};
</script>