<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/cooperation/add"
      :showButton="false"
    ></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-select v-model="status" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入关键字"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 10px"
    >
      <div
        style="
          display: flex;
          color: #909399;
          font-size: 14px;
          margin-bottom: 10px;
        "
      >
        <div style="margin-right: 10px">
          已提现金额：{{ statistics.succeedAmount }}
        </div>
        <div style="margin-right: 10px">
          待审核记录：{{ statistics.pendingCount }}
        </div>
      </div>
      
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column prop="userId" label="用户ID"> </el-table-column>
      <el-table-column prop="name" label="用户名">
        <template slot-scope="scope">
          {{ scope.row.userDTO.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="返佣总额">
        <template slot-scope="scope">
          {{ scope.row.userDTO.fanMoneyIn }}
        </template>
      </el-table-column>
      <el-table-column prop="money" label="提现金额"> </el-table-column>
      <el-table-column label="提现渠道">
        <template slot-scope="scope">
          {{ scope.row.userDTO.remark }}
        </template>
      </el-table-column>
      <el-table-column label="申请状态">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">待审核</div>
          <div v-if="scope.row.status == 1">已打款</div>
          <div v-if="scope.row.status == 2">拒绝</div>
        </template>
      </el-table-column>

      <el-table-column label="申请时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" v-if="scope.row.status==0">
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import updatePage from "./info.vue";
export default {
  name: "productList",
  components: {
    updatePage,
  },
  data() {
    return {
      updatePageVisible: false,
      editId: 0,
      loading: false,
      keyword: "",
      status:-2,
      total: 0,
      statistics:{},
      tableData: [],
      crumb: ["提现", "列表"],
      options: [
      {
          value: -2,
          label: "不限状态",
        },
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已打款",
        },
        {
          value: 2,
          label: "未通过",
        },
      ],
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    searchput(e) {
     this.getList(1)
    },
    getKeyword() {},
    edit(index) {
       let id = this.tableData[index].id;
      this.editId = id;
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword };
      } else {
        data = { pageIndex: p };
      }
      operate.getWithdrawalList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getWithdrawalList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    operate.getWithdrawalStatistics({ pageIndex: 1 }).then((response) => {
      this.statistics = response.data;
    });
  },
};
</script>