<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      :rules="rules"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="image">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-success="uploadCallBack"
          :on-remove="uploadRemove"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情顶图" prop="topImage">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-success="uploadTopCallBack"
          :on-remove="uploadTopRemove"
          :headers="headers"
        >
          <img
            v-if="ruleForm.topImage"
            :src="'/api' + ruleForm.topImage"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio v-model="ruleForm.sex" label="0">男</el-radio>
        <el-radio v-model="ruleForm.sex" label="1">女</el-radio>
      </el-form-item>
      <el-form-item label="等级" prop="level">
        <el-radio v-model="ruleForm.level" label="1">资深导师</el-radio>
        <el-radio v-model="ruleForm.level" label="2">金牌导师</el-radio>
        <el-radio v-model="ruleForm.level" label="3">王牌导师</el-radio>
        <el-radio v-model="ruleForm.level" label="4">高级导师</el-radio>
      </el-form-item>
      <el-form-item label="擅长内容" prop="skill">
        <el-input v-model="ruleForm.skill"></el-input>
      </el-form-item>
      <el-form-item label="简介" prop="shotDesc">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.shotDesc"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="详细简介" prop="description">
        <quill-editor
          class="editor"
          v-model="ruleForm.description"
          ref="descriptionEditorAdd"
          :options="editorOption"
          @change="onDescriptionChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="适合人群" prop="crowd">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.crowd"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="课程安排" prop="course">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.course"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="收费标准" prop="chargeDesc">
        <quill-editor
          class="editor"
          v-model="ruleForm.chargeDesc"
          ref="chargeDescEditorAdd"
          :options="editorOption"
          @change="onChargeDescChange($event)"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="非会员价" prop="charge">
        <el-input v-model="ruleForm.charge"></el-input>
      </el-form-item>
      <el-form-item label="会员价" prop="memberCharge">
        <el-input v-model="ruleForm.memberCharge"></el-input>
      </el-form-item>
      <el-form-item label="基础费用" prop="baseCharge">
        <el-input v-model="ruleForm.baseCharge"></el-input>
      </el-form-item>
      <el-form-item label="基础时间" prop="baseChargeTime">
        <el-input v-model="ruleForm.baseChargeTime"></el-input>
      </el-form-item>
      <el-form-item label="是否上线">
        <el-radio-group v-model="ruleForm.status">
        <el-radio :label="0">下线</el-radio>
        <el-radio :label="1">上线</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-upload
        class="rich-upload"
        action="/api/fileInfo/upload"
        :multiple="false"
        :show-file-list="false"
        :on-success="richUploadSuccess"
        :headers="headers"
        style="height: 10px"
      ></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        topImage:"",
        sex: "",
        level: "普通导师",
        skill: "",
        shotDesc: "",
        description: "",
        crowd: "",
        course: "",
        charge: "",
        memberCharge: "",
        chargeDesc: "",
        baseCharge:"",
        baseChargeTime:""
      },
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      }
    };
  },
  methods: {
    onChargeDescChange({ quill, html, text }) {
      this.chargeDesc = html;
    },
    onDescriptionChange({ quill, html, text }) {
      this.description = html;
    },
    submitForm() {
      for (let i in this.ruleForm) {
        if (this.ruleForm[i].length <= 0) {
          this.$message({
            message: "请完成所有内容",
            type: "error",
          });
          return;
        }
      }
      operate.addTeacher(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadTopCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.topImage = response.data;
      }
    },
    uploadTopRemove(file, fileList) {
      this.ruleForm.topImage = "";
    },
    //详细描述图片上传
    richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.chargeDescEditorAdd.quill;
        let _image = response.data;
        if (_image) {
          // 获取光标所在位置
          console.log("quill.getSelection().index", quill.getSelection());
          if (!quill.getSelection() || quill.getSelection() == null) {
            quill = this.$refs.descriptionEditorAdd.quill;
          }
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", '/api'+_image);
          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
  },
  mounted() {},
};
</script>