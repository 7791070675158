import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import Element from 'element-ui';
import Breadcrumb from '@/components/Breadcrumb.vue'
import filter from './utils/filter'
for (const key in filter) {
    Vue.filter(key,filter[key]);
}
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor)
Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.component('Breadcrumb', Breadcrumb)
Vue.config.productionTip = false
import '@/components/ef/index.css'
import './main.css'
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')