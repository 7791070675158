<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-select v-model="isMember" placeholder="会员状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入昵称" clearable size="small" style="width: 240px" v-model="keyword" @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
      <div style="
          display: flex;
          color: #909399;
          font-size: 14px;
          margin-bottom: 10px;
        " n>
        <div style="margin-right: 10px">
          用户总数：{{ statistics.totalCount }}
        </div>
        <div style="margin-right: 10px">
          会员总数：{{ statistics.memberCount }}
        </div>
        <div style="margin-right: 10px">
          充值金额：{{ statistics.chongMoney }}
        </div>
        <div style="margin-right: 10px">
          发放易豆：{{ statistics.chongPoints }}
        </div>
        <div style="margin-right: 10px">
          易豆余额：{{ statistics.points }}
        </div>
      </div>
      <div></div>
    </div>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="phone" label="手机"> </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <div v-if="scope.row.sex == 0">男</div>
          <div v-if="scope.row.sex == 1">女</div>
        </template>
      </el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
          <el-image style="width: 100px; height: 100px" :src="'/api' + scope.row.headimgurl" :fit="scale - down"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="累计充值">
        <template slot-scope="scope">

          <div>￥{{ scope.row.chongMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="chongPoints" label="累计获得易豆"> </el-table-column>
      <el-table-column prop="points" label="可用易豆"> </el-table-column>
      <el-table-column label="邀请人">
        <template slot-scope="scope">
          <div v-if="scope.row.fromUserDTO">{{ scope.row.fromUserDTO.nickname }}</div>
        </template>
      </el-table-column>
      <el-table-column label="上级代理">
        <template slot-scope="scope">

          <div v-if="scope.row.superiorUserDTO">{{ scope.row.superiorUserDTO.nickname }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是否可用">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949"
            @change="changeStatus(scope.row, scope.$index)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="会员状态">
        <template slot-scope="scope">
          <div v-if="scope.row.isMember == 0">普通用户</div>
          <div v-if="scope.row.isMember == 1">标准用户</div>
          <div v-if="scope.row.isMember == 2">待激活</div>
          <div v-if="scope.row.isMember == 3">会员待审核</div>
          <div v-if="scope.row.isMember == 4">高级用户</div>
          <div v-if="scope.row.isMember == 5">非凡用户</div>
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate('yyyy-M-d H:m:s') }}
        </template>
      </el-table-column>
      <el-table-column label="上次登陆时间">
        <template slot-scope="scope">
          {{ scope.row.lastLoginAt | formatDate('yyyy-M-d H:m:s') }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="edit(scope.$index)" type="text" size="small">
            详情
          </el-button>
          <el-button @click.native.prevent="changeMember(scope.$index)" type="text" size="small"
            v-if="scope.row.status == 1">
            修改会员等级
          </el-button>
          <el-button @click.native.prevent="changeDaili(scope.$index)" type="text" size="small"
            v-if="scope.row.superiorUserDTO">
            修改代理
          </el-button>
          <el-button @click.native.prevent="points(scope.$index)" type="text" size="small">
            更改易豆
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
    <el-dialog title="易豆" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
    <el-dialog title="修改等级" :visible.sync="memberVisible" width="80%">
      <member :id="editId" @closeMember="closeMember"></member>
    </el-dialog>
    <el-dialog title="修改代理" :visible.sync="dailiVisible" width="80%">
      <daili :id="editId" @closeDaili="closeDaili"></daili>
    </el-dialog>
  </div>
</template>

<script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
import member from "./member.vue";
import daili from "./daili.vue";
export default {
  components: {
    updatePage, member, daili
  },
  data() {
    return {
      isMember: -1,
      memberVisible: false,
      dailiVisible: false,
      editId: 0,
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      statistics: {},
      p: 1,
      updatePageVisible: false,
      crumb: ['用户', '列表'],
      options: [
        {
          value: -1,
          label: "不限会员状态",
        },
        {
          value: 0,
          label: "普通用户",
        },
        {
          value: 1,
          label: "标准用户",
        },
        {
          value: 4,
          label: "高级用户",
        },
        {
          value: 5,
          label: "非凡用户",
        },

      ]
    };
  },
  methods: {
    closeMember() {
      this.memberVisible = false;
    },
    changeMember(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.memberVisible = true;
    },
    closeDaili() {
      this.dailiVisible = false;
    },
    changeDaili(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.dailiVisible = true;
    },
    closeUpdatePate() {
      this.updatePageVisible = false;
      this.getList(this.p)
    },
    changeStatus(e, index) {
      operate.editUser({ id: e.id, status: e.status ? 0 : 1 }, "post").then((response) => {
        if (response.code == '0000') {
          this.$message({
            message: response.msg,
            type: 'success',
            onClose: function () {
              window.location.reload()
            }
          })
        }
      });
    },
    deleteRow(index, rows) {
      let _info = this.tableData[index]
      operate.deleteUser(_info.id).then((response) => {
        if (response.code == '0000') {
          this.$message({
            message: response.msg,
            type: 'success',
            onClose: function () {
              window.location.reload()
            }
          })
        }
      })
    },
    searchput() {
      this.getList(1)
    },
    getKeyword() { },
    points(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId)
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/info/" + id;
      this.$router.push(path);
    },
    getList(p) {
      this.p = p
      let data = { pageIndex: p }
      if (this.keyword.length > 0) {
        data.nickname = this.keyword
      }
      if (this.isMember!=-1) {
        data.isMember=this.isMember
      }
      operate.getUserList(data).then((response) => {
        this.tableData = response.data;
        this.tableData.forEach(function (item, index) {
          if (item.status == 1) {
            item.isEnable = true;
          } else {
            item.isEnable = false;
          }
        })
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getUserList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.tableData.forEach(function (item, index) {
        if (item.status == 1) {
          item.isEnable = true;
        } else {
          item.isEnable = false;
        }
      })
      this.total = response.total;
    });
    operate.getUserStatistics({ pageIndex: 1 }).then((response) => {
      this.statistics = response.data;
    });
  },
};
</script>