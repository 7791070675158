<template>
  <div class="right-content">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="老师">
        <el-select v-model="ruleForm.teacherId" placeholder="请选择">
          <el-option v-for="item in teachers" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="封面图">
        <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
          :on-success="uploadCallBack" :on-remove="uploadRemove" accept=".jpg, .png, jpeg" :headers="headers">
          <img v-if="ruleForm.image" :src="'/api/' + ruleForm.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="试看视频">
        <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false" accept="video/*"
          :on-success="uploadVideoCallBack" :on-remove="uploadVideoRemove" :headers="headers">
          <video width="178px" height="178px" v-if="ruleForm.video" controls>
            <source :src="'/api' + ruleForm.video" class="avatar" />
          </video>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!--   :http-request="uploadFullVideo" -->
      <el-form-item label="完整视频">
        <div v-if="fullVideo" style="display:flex;flex-wrap: wrap;">
          <video width="178px" height="178px" controls v-for="(v, i) in fullVideo" :key="i">
            <source :src="'/api' + v" class="avatar" />
          </video>
        </div>
        <el-upload :show-file-list="true" multiple class="avatar-uploader" action="/api/fileInfo/upload"
          :on-progress="fullVideoProgress" :on-success="uploadFullVideoCallBack" :on-remove="uploadFullVideoRemove"
          :headers="headers">
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- <el-upload action="/api/fileInfo/upload" list-type="picture-card" :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload> -->
      </el-form-item>
      <el-form-item label="完整视频路径" prop="fullVideo">
        <el-input type="textarea" v-model="fullVideo"></el-input>
      </el-form-item>
      <el-progress v-if="showFullVideoProgress" :stroke-width="6" :percentage="progressFullVideoPercent"></el-progress>
      <el-form-item label="简介">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.shortDesc">
        </el-input>
      </el-form-item>
      <el-form-item label="详细简介">
        <quill-editor class="editor" v-model="ruleForm.description" ref="descriptionEditorAdd" :options="editorOption"
          @change="onDescriptionChange($event)">
        </quill-editor>
      </el-form-item>
      <el-form-item label="价格" prop="charge">
        <el-input v-model="ruleForm.charge"></el-input>
      </el-form-item>
      <!-- <el-form-item label="会员价" prop="memberCharge">
        <el-input v-model="ruleForm.memberCharge"></el-input>
      </el-form-item> -->
      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">不开启</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否限时免费">
        <el-radio-group v-model="ruleForm.isfree">
          <el-radio :label="0">不免费</el-radio>
          <el-radio :label="1">免费</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="ruleForm.isfree">
        <el-form-item label="免费开始时间">
          <el-date-picker v-model="ruleForm.freeStartAt" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="免费结束时间">
          <el-date-picker v-model="ruleForm.freeStartEnd" type="date" value-format="yyyy-MM-dd" placeholder="请选择开时间">
          </el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="观看权限">
        <el-select v-model="ruleForm.permission" placeholder="请选择">
          <el-option v-for="item in members" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-upload class="rich-upload" action="/api/fileInfo/upload" :multiple="false" :show-file-list="false"
        :on-success="richUploadSuccess" :headers="headers" style="height: 10px"></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Message, Loading } from "element-ui"
import { upload, uploadByPieces } from "@/utils/upload.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      fullVideo: [],
      fullVideoPath: [],
      teachers: [],
      members: [
      {
          value: 9999,
          name: '默认'
        },
        {
          value: 0,
          name: '普通用户'
        },
        {
          value: 1,
          name: '标准用户'
        }, {
          value: 4,
          name: '高级用户'
        },
        {
          value: 5,
          name: '非凡用户'
        },
      ],
      ruleForm: {
        permission: 9999,
        teacherId: '',
        type: 2,
        isfree: 0,
        freeStartAt: '',
        freeStartEnd: '',
        title: "",
        image: "",
        shortDesc: "",
        description: "",
        video: "",
        charge: "",
        memberCharge: "",
      },
      showFullVideoProgress: false,
      progressFullVideoPercent: 0,
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
    };
  },
  methods: {
    onDescriptionChange({ quill, html, text }) {
      this.description = html;
    },
    submitForm() {
      for (let i in this.ruleForm) {
        console.log(i)
        if (i == 'freeStartAt' || i == 'freeStartEnd') {
          if (this.ruleForm.isfree == 1) {
            if (this.ruleForm[i].length <= 0) {
              this.$message({
                message: "请完成所有内容",
                type: "error",
              });
              return;
            }
          }
        } else {
          if (this.ruleForm[i].length <= 0) {
            this.$message({
              message: "请完成所有内容",
              type: "error",
            });
            return;
          }
        }

      }
      if (this.fullVideo.length <= 0) {
        this.$message({
          message: "请完成所有内容",
          type: "error",
        });
        return;
      }
      let _fulVideo = []
      let _videoPath = this.fullVideoPath.split(';')
      _videoPath.forEach(function (item, index) {
        let _obj = {}
        if (!item.includes('/upload') && !item.includes('http')) {
          let _url = '/static/image/upload/' + item
          _obj.fullVideo = _url
        } else {
          _obj.fullVideo = item
        }
        _fulVideo.push(_obj)
      })
      this.ruleForm.videoDTOList = _fulVideo
      if (this.ruleForm.isfree == 0) {
        this.ruleForm.freeStartAt = ''
        this.ruleForm.freeStartEnd = ''
      } else {
        this.ruleForm.freeStartAt = new Date(this.ruleForm.freeStartAt).getTime()
        this.ruleForm.freeEndAt = new Date(this.ruleForm.freeEndAt).getTime()
      }
      this.ruleForm.memberCharge=this.ruleForm.charge
      operate.addBasicknowledge(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
      console.log(this.ruleForm);
    },
    uploadRemove(file, fileList) {
      console.log('file', file)
    },
    uploadFullVideoCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.showFullVideoProgress = false;
        this.fullVideo.push(response.data)
        this.fullVideoPath = this.fullVideo.join(';')
      }
    },
    uploadFullVideoRemove(file, fileList) {
      console.log('fileList', fileList)
      let _fileList = []
      fileList.forEach(function (item, index) {
        _fileList.push(item.response.data)
      })
      this.fullVideo = _fileList;
      this.fullVideoPath = this.fullVideo.join(';')
    },
    fullVideoProgress(event, file, fileList) {
      let _percent = Math.floor(file.percentage);
      if (_percent >= 99) {
        console.log("_percent", _percent);
        this.showFullVideoProgress = false;
      } else {
        console.log("_percent_percent", _percent);
        this.showFullVideoProgress = true;
        this.progressFullVideoPercent = _percent;
      }
      console.log("_percent_percent", _percent);
    },
    async uploadFullVideo({ data, file }) {
      // data是上传时附带的额外参数，file是文件
      let url = "/api/fileInfo/upload"; //上传文件接口
      let loadingInstance = Loading.service({
        text: "正在上传文件，请稍后...",
      });
      try {
        console.log('upload file', file)
        // 如果文件小于5MB，直接上传
        if (file.size < 5 * 1024 * 1024) {
          let formData = new FormData();
          for (let key in data) {
            formData.append(key, data[key]);
          }
          formData.append("file", file);

          const res = await upload(url, formData, this.headers);
          loadingInstance.close();
          return res;
        } else {
          console.log('upload data', data)
          // 如果文件大于等于5MB，分片上传
          let _data = {}
          _data.file = file;
          const res = await uploadByPieces(url, _data, this.headers);
          loadingInstance.close();
          return res;
        }
      } catch (e) {
        console.log('upload file error', e)
        loadingInstance.close();
        return e;
      }
    },
    uploadVideoCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.video = response.data;
      }
    },
    uploadVideoRemove(file, fileList) {
      this.ruleForm.video = "";
    },
    //详细描述图片上传
    richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.descriptionEditorAdd.quill;
        let _image = response.data;
        if (_image) {
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", "/api" + _image);
          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
  },
  mounted() {
    operate.allTeacher().then((res) => {
      this.teachers = res.data
    });
  },
};
</script>