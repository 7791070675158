<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="昵称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
    
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
     
     
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
        checkPass: "",
        id:0
      }
    };
  },
  methods: {
    submitForm() {
    
      let _data = {
        id: this.ruleForm.id,
        name: this.ruleForm.name,
      };
      if( this.ruleForm.password&& this.ruleForm.checkPass){
        _data.password=this.ruleForm.password
        _data.repass=this.ruleForm.checkPass
      }
      operate.editManager(_data, "post").then((response) => {
        if(response.code=='0000'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
      });
    },
  },
  mounted() {
    let param = this.$route.params;
    let id = param["id"];
    operate.getManagerInfo(id).then((response) => {
      this.ruleForm = response.data;
      this.ruleForm.password=""
    });
  },
};
</script>