import axios from "axios";
import store from "../store"
import { Message, Loading } from "element-ui"
// loading开始 方法
var loading;
function startLoading() {
  // element-ui loading 服务调用方式  
  loading = Loading.service({
    lock: true,
    text: '拼命加载中...',
    spinner: 'el-icon-loading'  // 自定义图标
    // background: '#ffffff'
  })
}

// loading结束 方法
function endLoading() {
  loading.close()

}
// let token = localStorage.getItem('token')
// console.log('token', token)
// if (!token || token == 'undefined') {
//   var request = axios.create({
//     baseURL: '/api/',
//     timeout: 20000
//   })
// } else {
//   var request = axios.create({
//     baseURL: '/api/',
//     timeout: 20000,
//     headers: {
//       'X-PD-TOKEN': token
//     }
//   })
// }
var request = axios.create({
  baseURL: '/api/',
  timeout: 20000
})
request.interceptors.request.use(function (config) {
  config['headers']['X-PD-TOKEN'] = localStorage.getItem('token') || ''
  if (config['url'] != 'manager/login') {

    let userInfo = localStorage.getItem('userInfo')
    // console.log('userInfo',userInfo)
    let jsonUserInfo = JSON.parse(userInfo)
    if (!jsonUserInfo || jsonUserInfo == 'undefined') {
      window.location.href = "/"
    } else {
      if (!config.hasOwnProperty('params')) {
        config['params'] = {}
      }
      let _data = [];
      if (!config.hasOwnProperty('data')) {
        config['data'] = ''
      } else {
        _data = config['data']
      }
      config['data'] = _data
    }
  }
  console.log('config',config)
  startLoading();
  return config;
}, function (error) {
  endLoading();
  return Promise.reject(error)
});
request.interceptors.response.use(function (res) {
  console.log('.....', res)
  endLoading();
  let response = res.data;
  if (response.code != '0000') {

    if (response.code === '403') {
        window.location.href = "/#/"
    } else {
      Message({
        type: 'error',
        message: response.msg || "error",
        duration: 1000,
        showClose: true
      });
    }
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});
export default request;