<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
    <el-form-item label="标题" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-success="uploadCallBack"
          :on-remove="uploadRemove"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="金额" prop="money">
        <el-input v-model="ruleForm.money"></el-input>
      </el-form-item>
      <el-form-item label="获得易豆" prop="points">
        <el-input v-model="ruleForm.points"></el-input>
      </el-form-item>
      <el-form-item label="会员加送易豆" prop="memberPoints">
        <el-input v-model="ruleForm.memberPoints"></el-input>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.isEnable">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        money:"",
        points: '',
        memberPoints:'',
        isEnable:1,
        id:0
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
    };
  },
  methods: {
    submitForm() {
      operate.editProduct(this.ruleForm, "post").then((response) => {
        if(response.code=='0000'){
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        console.log(response.data)
        this.ruleForm.image = response.data;
      }
    },
    getInfo(id) {
    operate.getProductInfo(id).then((response) => {
      this.ruleForm = response.data;
      
    });
  },
  },

  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>