<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="联系人" prop="name">
        <el-input v-model="ruleForm.name" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="mobile">
        <el-input v-model="ruleForm.mobile" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="省" prop="province">
        <el-input v-model="ruleForm.province" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="市" prop="city">
        <el-input v-model="ruleForm.city" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="区/县" prop="district">
        <el-input v-model="ruleForm.district" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="自我介绍" prop="description">
        <el-input
          :disabled="true"
          type="textarea"
          :rows="4"
          v-model="ruleForm.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="status">
          <el-radio :label="0">待审</el-radio>
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">拒绝</el-radio>
          <el-radio :label="3">不合作</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        mobile: "",
        province: "",
        city: "",
        district: "",
        description: "",
        status: "",
        id: 0,
      },
      status:''
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
  },
  watch:{
      id(newval,oldval){
        let loadingInstance=Loading.service({
          text: '数据获取中',
        });
        this.id = newval;
        this.getInfo(newval);
        setTimeout(() => {
          loadingInstance.close();
        }, 2000);
      }
  },
  methods: {
    getInfo(id) {
      operate.getCooperationInfo(id).then((response) => {
      this.ruleForm = response.data;
      this.status=response.data.status
    });
    },
    submitForm() {
      this.ruleForm.status=this.status
      operate.editCooperation(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
              this.$emit('closeUpdatePate');
            }, 2000);
        }
      });
    },
  },
  mounted() {
    // let param = this.$route.params;
    // let id = param["id"];
    // operate.getCooperationInfo(id).then((response) => {
    //   this.ruleForm = response.data;
    // });
  },
};
</script>