<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column>
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="ID">{{props.row.id}}</el-form-item>
            <el-form-item label="操作人">{{ props.row.createdBy }}</el-form-item>
            <el-form-item label="操作内容">{{props.row.description}}</el-form-item>
            <el-form-item label="操作时间">
                {{ props.row.createdAt | formatDate('yyyy-M-d H:m:s') }}
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
    
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
   
  </div>
</template>

<script>
import operate from "../../api/api.js";
export default {
  components: {
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible: false,
      addPageVisible: false,
      editId: 0,
      tableData: [],
      crumb: ['操作日志', '列表']
    };
  },
  methods: {
   
    getList(p) {
      let data = {}
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword }
      } else {
        data == { pageIndex: p }
      }
      operate.getOperateLogList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getOperateLogList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>
<style>
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>