<template>
  <div class="right-content">
    <el-form label-width="100px" class="demo-ruleForm">
      <el-form-item label="选择等级">
        <el-select v-model="isMember" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      user_id: 0,
      isMember: '',
      options: [
      {
          value: 0,
          label: "普通用户",
        },
        {
          value: 1,
          label: "标准用户",
        },
        {
          value: 4,
          label: "高级用户",
        },
        {
          value: 5,
          label: "非凡用户",
        },
      ],
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: '数据获取中',
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    }
  },
  methods: {
    getInfo(id) {
      operate.getUserInfo(id).then((response) => {
        let info = response.data;
        this.user_id = info.id;
      });
    },
    submitForm() {
      operate.editUser({ id: this.user_id, isMember: this.isMember }, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          this.$emit('closeMember');
        }
      });
    },
  },
  mounted() {
    // let param = this.$route.params;
    // let id = param["id"];
    // operate.getCooperationInfo(id).then((response) => {
    //   this.ruleForm = response.data;
    // });
  },
};
</script>