<template>
  <div class="right-content">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="封面图">
        <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
          :on-success="uploadCallBack" :on-remove="uploadRemove" accept=".jpg, .png, jpeg" :headers="headers">
          <img v-if="ruleForm.image" :src="'/api/' + ruleForm.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="视频">
        <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false" accept="video/*"
          :on-success="uploadVideoCallBack" :on-remove="uploadVideoRemove" :headers="headers">
          <video width="178px" height="178px" v-if="ruleForm.video" controls>
            <source :src="'/api' + ruleForm.video" class="avatar" />
          </video>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="ruleForm.shortDesc">
        </el-input>
      </el-form-item>
      <el-form-item label="详细简介">
        <quill-editor class="editor" v-model="ruleForm.description" ref="descriptionEditorAdd" :options="editorOption"
          @change="onDescriptionChange($event)">
        </quill-editor>
      </el-form-item>

      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">不开启</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>



      <el-upload class="rich-upload" action="/api/fileInfo/upload" :multiple="false" :show-file-list="false"
        :on-success="richUploadSuccess" :headers="headers" style="height: 10px"></el-upload>
      <el-form-item>
        <el-button type="primary" @click="submitForm">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Message, Loading } from "element-ui"
import { upload, uploadByPieces } from "@/utils/upload.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    //界面组件引用
    quillEditor,
  },
  data() {
    return {
      ruleForm: {
        type: 6,//什么是超级个体
        isfree: 0,
        title: "",
        image: "",
        shortDesc: "",
        video: "",
        description: ""
      },
     
      showFullVideoProgress: false,
      progressFullVideoPercent: 0,
      editorOption: {
        placeholder: "请输入",
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image"], // 链接、图片，需要视频的可以加上video
            ],
            handlers: {
              //此处是图片上传时候需要使用到的
              image: function (value) {
                console.log("value", value);
                if (value) {
                  // 点击图片
                  document.querySelector(".rich-upload input").click();
                }
              },
            },
          },
        },
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
    };
  },
  methods: {
    onDescriptionChange({ quill, html, text }) {
      this.description = html;
    },
    submitForm() {
      for (let i in this.ruleForm) {

        if (this.ruleForm[i].length <= 0) {
          this.$message({
            message: "请完成所有内容",
            type: "error",
          });
          return;
        }
      }
     
      operate.addBasicknowledge(this.ruleForm).then((res) => {
        if (res.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeAddPate");
          }, 2000);
        }
      });
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
      console.log(this.ruleForm);
    },
    uploadRemove(file, fileList) {
      console.log('file', file)
    },
    uploadVideoCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.video = response.data;
      }
    },
    uploadVideoRemove(file, fileList) {
      this.ruleForm.video = "";
    },
    //详细描述图片上传
    richUploadSuccess(response, file, fileList) {
      if (response.code == "0000") {
        let quill = this.$refs.descriptionEditorAdd.quill;
        let _image = response.data;
        if (_image) {
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          var fileExtension = _image.split('.').pop().toLowerCase();
          // 插入图片，res为服务器返回的图片链接地址
          if (fileExtension == 'mp4') {
            quill.insertEmbed(length, "video", '/api' + _image);
          } else {
            quill.insertEmbed(length, "image", '/api' + _image);
          }

          // 调整光标到最后
          quill.setSelection(length + 1);
        } else {
          // 提示信息，需引入Message
          this.$message.error("图片插入失败！");
        }
      }
    },
  },
  mounted() {
   
  },
};
</script>