<template>
  <div class="right-content">
    
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="nickname"  label="昵称"></el-table-column>
      <el-table-column prop="phone" label="手机"> </el-table-column>
      <el-table-column label="性别"> 
        <template slot-scope="scope">
            <div v-if="scope.row.sex==0">男</div> <div v-if="scope.row.sex==1">女</div>
          </template>
      </el-table-column>
      <el-table-column label="头像">
        <template slot-scope="scope">
        <el-image
          style="width: 100px; height: 100px"
          :src="'/api'+scope.row.headimgurl"
          :fit="scale-down"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="累计充值"> 
        <template slot-scope="scope">
          
            <div>￥{{ scope.row.chongMoney }}</div>
          </template>
      </el-table-column>
        <el-table-column prop="chongPoints" label="累计获得易豆"> </el-table-column>
      <el-table-column prop="points" label="可用易豆"> </el-table-column>
      <el-table-column label="邀请人"> 
        <template slot-scope="scope">
            <div v-if="scope.row.fromUserDTO">{{ scope.row.fromUserDTO.nickname }}</div>
          </template>
      </el-table-column>
      <el-table-column label="上级代理"> 
        <template slot-scope="scope">
          
            <div v-if="scope.row.superiorUserDTO">{{ scope.row.superiorUserDTO.nickname }}</div>
          </template>
      </el-table-column>
      <el-table-column label="是否可用">
        <template slot-scope="scope">
            <el-switch
            v-model="scope.row.isEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="changeStatus(scope.row,scope.$index)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="状态"> 
        <template slot-scope="scope">
            <div v-if="scope.row.isMember==0">非会员</div> 
            <div v-if="scope.row.isMember==1">标准用户</div>
            <div v-if="scope.row.isMember==2">待激活</div>
            <div v-if="scope.row.isMember==3">标准用户待审核</div>
            <div v-if="scope.row.isMember==4">高级用户</div>
            <div v-if="scope.row.isMember==5">非凡用户</div>
          </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
      <el-table-column label="上次登陆时间">
        <template slot-scope="scope">
          {{scope.row.lastLoginAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  components: {
  },
  data() {
    return {
      memberVisible:false,
      dailiVisible:false,
      editId:0,
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      statistics:{},
      p:1,
      updatePageVisible:false,
      crumb:['下级','列表']
    };
  },
  props: ['id'],
  created() {
    this.getList(1)
  },
  watch:{
      id(newval,oldval){
        let loadingInstance=Loading.service({
          text: '数据获取中',
        });
        this.id = newval;
        this.getList(1)
        setTimeout(() => {
          loadingInstance.close();
        }, 2000);
      }
  },
  methods: {
  
    searchput() {
      this.getList(1)
    },
    getKeyword() {},
  
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/info/" + id;
      this.$router.push(path);
    },
    getList(p) {
      let data={
        superiorUserId:this.id
      }
      this.p=p
      if(this.keyword.length>0){
        data={ superiorUserId:this.id, pageIndex: p, nickname: this.keyword }
      }else{
        data={ superiorUserId:this.id, pageIndex: p}
      }
      operate.getUserList(data).then((response) => {
      this.tableData = response.data;
      this.tableData.forEach(function(item,index){
        if(item.status==1){
          item.isEnable=true;
        }else{
          item.isEnable=false;
        }
      })
      this.total = response.total;
    });
    },
  },
  mounted() {
   
  },
};
</script>