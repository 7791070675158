<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="男" prop="nan">
        <el-input v-model="ruleForm.nan"></el-input>
      </el-form-item>
      <el-form-item label="女" prop="nv">
        <el-input v-model="ruleForm.nv"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="nv">
        <el-input
          v-model="ruleForm.content"
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        nan: "",
        nv: "",
        content: "",
        id: 0,
      },
    };
  },
  methods: {
    submitForm() {
      let _data = {
        id: this.ruleForm.id,
        title: this.ruleForm.title,
        content: this.ruleForm.content,
        type: 4,
      };
      operate.editHehunShengXiao(_data, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    getInfo(id) {
      operate.getHehunShengXiaoInfo(id).then((response) => {
        this.ruleForm = response.data;
      });
    },
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>