<template>
    <div style="height:960px" v-loading="loading" element-loading-text="登录中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)" class="login-container">
   
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
        <!-- 头像区域 -->
        <div v-if="TxStatus" class="avatar-box">
          <img src="../../assets/user.png" alt="">
        </div>
   
        <div class="title-container">
          <h3 class="title">后台管理系统</h3>
        </div>
   
        <el-form-item prop="username">
          <el-input ref="username" v-model="loginForm.name" placeholder="Username" name="username" type="text" tabindex="1" auto-complete="on" />
        </el-form-item>
   
        <el-form-item prop="password">
          <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType" placeholder="Password" name="password" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
        </el-form-item>
        <div>
          <el-button type="primary" style="width:100%;margin-bottom:20px;" @click.native.prevent="handleLogin">登录</el-button>
        </div>
   
      </el-form>
    </div>
  </template>
   
  <script>
  // 引入去除空格工具
   
import { validUsername } from '@/utils/validate'
import { login } from "../../api/user";
  export default {
    name: 'Login',
    data() {
        const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('用户名不能为空！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length <0) {
        callback(new Error('密码最少为6位字符！'))
      } else {
        callback()
      }
    }

      return {
        // 头像状态
        TxStatus: true,
        loginForm: {
          name: '',
          password: ''
        },
        // 登录规则
        loginRules: {
            name: [{ required: true, trigger: 'blur', validator: validateUsername }],
            password: [{ required: true, trigger: 'blur', validator: validatePassword }]
        },
        loading: false,
        passwordType: 'password',
        redirect: undefined
      }
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    methods: {
      showPwd() {
        if (this.passwordType === 'password') {
          this.passwordType = ''
        } else {
          this.passwordType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      // 登录业务
      handleLogin() {
        login({name:this.loginForm.name,password:this.loginForm.password}).then((res)=>{
          if(res.code=='0000'){
          
            let _userInfo=res.data.managerDTO
            localStorage.setItem('userInfo',JSON.stringify(_userInfo))
            localStorage.setItem('token',res.data.token)
                
                this.$router.replace('/bd');
           }
        })
      },
      // 注册业务
      register() {
        console.log('123')
        this.$router.push({ name: 'register' })
      }
    },
    mounted() {
      localStorage.clear() 
    },
  }
  </script>
   
  <style lang="scss">
  $bg: #283443;
  $light_gray: #fff;
  $cursor: #000;
  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
      color: $cursor;
    }
  }
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
   
      input {
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        height: 47px;
        caret-color: $cursor;
      }
    }
   
    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }
  }
  </style>
   
  <style lang="scss" scoped>
  $bg: #2d3a4b;
  $dark_gray: #889aa4;
  $light_gray: #eee;
   
  .login-container {
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    background: url(~@/assets/static/images/login-bg.jpg);
    background-size: 100% 100%;
     // 头像
    .avatar-box {
      margin: 0 auto;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px solid #409eff;
      box-shadow: 0 0 10px #409eff;
      position: relative;
      bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
   
    .login-form {
      position: relative;
      width: 520px;
      max-width: 100%;
      padding: 160px 35px 0;
      margin: 0 auto;
      overflow: hidden;
   
    }
   
    .tips {
      font-size: 18px;
      text-align: center;
      color: #000;
      margin-bottom: 10px;
    }
   
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }
   
    .title-container {
      position: relative;
   
      .title {
        font-size: 30px;
        color: $light_gray;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: 500;
      }
    }
   
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
  </style>