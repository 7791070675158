<template>
    <div style="margin: 10px 0;display:flex;justify-content: space-between;">
    <el-breadcrumb separator-class="el-icon-arrow-right">

      <el-breadcrumb-item v-for="(item, index) in crumb" :key="index">{{item }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="showButton">
        <el-row>
            <el-button @click="handle">添加</el-button>
        </el-row>
    </div>
  </div>
</template>
<script>
export default ({
    props:{
        addpath:{
            type:String,
            default:''
        },
        crumb:{
            type:Array,
            default:[]
        },
        showButton:false
    },
    data() {
        return {

        }
    },
    methods:{
        handle(){
            this.$router.push(this.addpath)
        }
    }
})
</script>
