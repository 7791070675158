<template>
  <div class="right-content">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-tabs v-model="activeName">
        <el-tab-pane label="小程序" name="xiaochengxu">
          <el-form-item label="小程序APPID" prop="wechat_app_id">
            <el-input v-model="ruleForm.wechat_app_id"></el-input>
          </el-form-item>
          <el-form-item label="小程序密钥" prop="wechat_app_secret">
            <el-input type="textarea" :rows="3" v-model="ruleForm.wechat_app_secret"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="微信支付" name="weixinpay">
          <el-form-item label="微信商户号" prop="wechat_merchant_id">
            <el-input v-model="ruleForm.wechat_merchant_id"></el-input>
          </el-form-item>
          <el-form-item label="支付Key" prop="wechat_api_v3_key">
            <el-input v-model="ruleForm.wechat_api_v3_key"></el-input>
          </el-form-item>
          <el-form-item label="支付密钥" prop="wechat_private_key">
            <el-input type="textarea" :rows="3" v-model="ruleForm.wechat_private_key"></el-input>
          </el-form-item>
          <el-form-item label="支付序列号" prop="wechat_merchant_serial_number">
            <el-input v-model="ruleForm.wechat_merchant_serial_number"></el-input>
          </el-form-item>
          <el-form-item label="支付通知URL" prop="wechat_notify_url">
            <el-input v-model="ruleForm.wechat_notify_url"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="排盘" name="paipan">
          <el-form-item label="排盘Key" prop="paipan_app_key">
            <el-input v-model="ruleForm.paipan_app_key"></el-input>
          </el-form-item>
          <el-form-item label="排盘URL" prop="paipan_url">
            <el-input v-model="ruleForm.paipan_url"></el-input>
          </el-form-item>
          <el-form-item label="排盘报告价格" prop="paipan_charge">
            <el-input v-model="ruleForm.paipan_charge"></el-input>
          </el-form-item>
          <el-form-item label="排盘报告会员价格" prop="paipan_member_charge">
            <el-input v-model="ruleForm.paipan_member_charge"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="合婚" name="hehun">
          <el-form-item label="合婚报告价格" prop="hehun_charge">
            <el-input v-model="ruleForm.hehun_charge"></el-input>
          </el-form-item>
          <el-form-item label="合婚报告会员价格" prop="hehun_member_charge">
            <el-input v-model="ruleForm.hehun_member_charge"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="客服" name="kefu">
          <el-form-item label="客服微信" prop="kefu_weixin">
            <el-input v-model="ruleForm.kefu_weixin"></el-input>
          </el-form-item>
          <el-form-item label="客服微信二维码">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'kefu_qrcode')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.kefu_qrcode" :src="'/api' + ruleForm.kefu_qrcode" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="生肖图" name="shengxiao">
          <el-form-item label="鼠">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_shu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_shu" :src="'/api' + ruleForm.shengxiao_picture_shu"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="牛">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_niu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_niu" :src="'/api' + ruleForm.shengxiao_picture_niu"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="虎">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_hu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_hu" :src="'/api' + ruleForm.shengxiao_picture_hu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="兔">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_tu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_tu" :src="'/api' + ruleForm.shengxiao_picture_tu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="龙">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_long')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_long" :src="'/api' + ruleForm.shengxiao_picture_long"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="蛇">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_she')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_she" :src="'/api' + ruleForm.shengxiao_picture_she"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="马">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_ma')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_ma" :src="'/api' + ruleForm.shengxiao_picture_ma" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="羊">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_yang')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_yang" :src="'/api' + ruleForm.shengxiao_picture_yang"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="猴">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_hou')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_hou" :src="'/api' + ruleForm.shengxiao_picture_hou"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="鸡">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_ji')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_ji" :src="'/api' + ruleForm.shengxiao_picture_ji" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="狗">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_gou')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_gou" :src="'/api' + ruleForm.shengxiao_picture_gou"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="猪">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'shengxiao_picture_zhu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.shengxiao_picture_zhu" :src="'/api' + ruleForm.shengxiao_picture_zhu"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-tab-pane>
        <el-tab-pane label="五行图" name="wuxing">
          <el-form-item label="甲木">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_jiamu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_jiamu" :src="'/api' + ruleForm.wuxing_picture_jiamu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="乙木">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_yimu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_yimu" :src="'/api' + ruleForm.wuxing_picture_yimu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="丙火">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_binghuo')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_binghuo" :src="'/api' + ruleForm.wuxing_picture_binghuo"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="丁火">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_dinghuo')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_dinghuo" :src="'/api' + ruleForm.wuxing_picture_dinghuo"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="戊土">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_wutu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_wutu" :src="'/api' + ruleForm.wuxing_picture_wutu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="己土">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_jitu')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_jitu" :src="'/api' + ruleForm.wuxing_picture_jitu" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="庚金">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_gengjin')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_gengjin" :src="'/api' + ruleForm.wuxing_picture_gengjin"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="辛金">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_xinjin')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_xinjin" :src="'/api' + ruleForm.wuxing_picture_xinjin"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="壬水">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_renshui')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_renshui" :src="'/api' + ruleForm.wuxing_picture_renshui"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="癸水">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wuxing_picture_kuishui')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wuxing_picture_kuishui" :src="'/api' + ruleForm.wuxing_picture_kuishui"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="邀请背景图" name="yaoqing">
          <el-form-item label="背景图">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response, file, fileList) => uploadCallBack(response, file, fileList, 'wechat_haibao_back')"
              :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.wechat_haibao_back" :src="'/api' + ruleForm.wechat_haibao_back" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-tab-pane>
        <!-- <el-tab-pane label="合婚背景图" name="hehun">
          <el-form-item label="男女">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response,file,fileList)=>uploadCallBack(response,file,fileList,'hehun_picture_back_nan')" :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.hehun_picture_back_nan" :src="'/api' + ruleForm.hehun_picture_back_nan"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="女男">
            <el-upload class="avatar-uploader" action="/api/fileInfo/upload" :show-file-list="false"
              :on-success="(response,file,fileList)=>uploadCallBack(response,file,fileList,'hehun_picture_back_nv')" :on-remove="uploadRemove" :headers="headers">
              <img v-if="ruleForm.hehun_picture_back_nv" :src="'/api' + ruleForm.hehun_picture_back_nv"
                class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        
        </el-tab-pane> -->
        <el-tab-pane label="分销" name="fenxiao">
          <el-form-item label="分成比例" prop="distribution_percent">
            <el-input v-model="ruleForm.distribution_percent"></el-input>
          </el-form-item>
          <el-form-item label="分配前几名代理商" prop="member_cooperation_num">
            <el-input v-model="ruleForm.member_cooperation_num"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="其他" name="qita">
          <el-form-item label="激活提示" prop="jihuotishi">
            <el-input type="textarea" :rows="3" v-model="ruleForm.jihuotishi"></el-input>
          </el-form-item>
          <el-form-item label="邀请新用户送积分" prop="invite_new_user_give_point">
            <el-input v-model="ruleForm.invite_new_user_give_point"></el-input>
          </el-form-item>
          <el-form-item label="新会员赠送积分" prop="new_member_give_point">
            <el-input v-model="ruleForm.new_member_give_point"></el-input>
          </el-form-item>
          <el-form-item label="新注册用户赠送积分" prop="new_user_give_point">
            <el-input v-model="ruleForm.new_user_give_point"></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import operate from "../../api/api.js";
export default {
  data() {

    return {
      activeName: 'xiaochengxu',
      tableData: [],
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
      ruleForm: {
        wechat_merchant_id: '',
        wechat_private_key: '',
        wechat_merchant_serial_number: '',
        wechat_api_v3_key: '',
        wechat_app_id: '',
        wechat_app_secret: '',
        paipan_app_key: '',
        paipan_url: '',
        distribution_percent: '',
        wechat_notify_url: '',
        member_cooperation_num: '',
        paipan_member_charge: '',
        paipan_charge: '',
        kefu_qrcode: '',
        kefu_weixin: '',
        jihuotishi: '',
        shengxiao_picture_shu: '',
        shengxiao_picture_niu: '',
        shengxiao_picture_hu: '',
        shengxiao_picture_tu: '',
        shengxiao_picture_long: '',
        shengxiao_picture_she: '',
        shengxiao_picture_ma: '',
        shengxiao_picture_yang: '',
        shengxiao_picture_hou: '',
        shengxiao_picture_ji: '',
        shengxiao_picture_gou: '',
        shengxiao_picture_zhu: '',
        hehun_member_charge: '',
        hehun_charge: '',
        wechat_haibao_back: '',

        wuxing_picture_jiamu: '',
        wuxing_picture_yimu: '',
        wuxing_picture_yinmu: '',
        wuxing_picture_maomu: '',
        wuxing_picture_binghuo: '',
        wuxing_picture_dinghuo: '',
        wuxing_picture_sihuo: '',
        wuxing_picture_wuhuo: '',
        wuxing_picture_wutu: '',
        wuxing_picture_jitu: '',
        wuxing_picture_chentu: '',
        wuxing_picture_xutu: '',
        wuxing_picture_choutu: '',
        wuxing_picture_weitu: '',
        wuxing_picture_gengjin: '',
        wuxing_picture_xinjin: '',
        wuxing_picture_shenjin: '',
        wuxing_picture_youjin: '',
        wuxing_picture_renshui: '',
        wuxing_picture_kuishui: '',
        wuxing_picture_haishui: '',
        wuxing_picture_zishui: '',
        hehun_picture_back_nan: '',
        hehun_picture_back_nv: '',
        shenhe: '',
        invite_new_user_give_point:0,
        new_member_give_point:0,
        new_user_give_point:0
      }
    };
  },
  methods: {
    uploadRemove(file, fileList) {
      this.ruleForm.kefu_qrcode = "";
    },
    uploadCallBack(response, file, fileList, param) {
      console.log('param', param)
      if (response.code == "0000") {
        this.ruleForm[param] = response.data;
      }
      console.log('this.ruleForm', this.ruleForm)
    },
    onSubmit() {
      let data = [];
      let that = this
      that.tableData.forEach(function (item, index) {
        let _data = {};
        _data.id = item.id
        _data.value = that.ruleForm[item.name]
        data.push(_data);
      })
      operate.editConfig(data).then((res) => {
        if (res.code == "0000") {
          this.$message({
            message: "更新成功",
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    }
  },
  mounted() {
    let that = this
    operate.getConfigList().then((response) => {
      let _data = response.data;
      that.tableData = _data;
      _data.forEach(function (item, index) {
        that.ruleForm[item.name] = item.value
      })
      console.log(that.ruleForm)
    });
  },
};
</script>