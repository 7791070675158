<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input placeholder="请输入" clearable size="small" style="width: 240px" v-model="keyword" @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="姓名">{{props.row.paipanDTO2.name}}</el-form-item>
            <el-form-item label="城市">{{ props.row.paipanDTO2.city }}</el-form-item>
            <el-form-item label="年">{{props.row.paipanDTO2.year}}</el-form-item>
            <el-form-item label="月">{{props.row.paipanDTO2.month}}</el-form-item>
            <el-form-item label="日">{{props.row.paipanDTO2.day}}</el-form-item>
            <el-form-item label="小时">{{props.row.paipanDTO2.hour}}</el-form-item>
            <el-form-item label="分钟">{{props.row.paipanDTO2.minute}}</el-form-item>
            <el-form-item label="性别">
              <div v-if="props.row.paipanDTO2.sex == 1">男</div>
                <div v-if="props.row.paipanDTO2.sex == 0">女</div>
            </el-form-item>
            <el-form-item label="是否阴历">
                <div v-if="props.row.paipanDTO2.islunar == 0">阳历</div>
                <div v-if="props.row.paipanDTO2.islunar == 1">阴历</div>
            </el-form-item>
            <el-form-item label="是否太阳时">
                <div v-if="props.row.paipanDTO2.istaiyang == 0">否</div>
                <div v-if="props.row.paipanDTO2.istaiyang == 1">是</div>
            </el-form-item>
            <el-form-item label="是否是闰月">
                <div v-if="props.row.paipanDTO2.islunarmonth == 1">是</div>
                <div v-if="props.row.paipanDTO2.islunarmonth == 2">否</div>
            </el-form-item>
            <el-form-item label="测试时间">
                {{ props.row.paipanDTO2.createdAt | formatDate('yyyy-M-d H:m:s') }}
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="合婚ID"> </el-table-column>
      <el-table-column prop="paipanDTO1.name" label="姓名"> </el-table-column>
      <el-table-column prop="paipanDTO1.city" label="城市"> </el-table-column>
      <el-table-column prop="paipanDTO1.year" label="年"> </el-table-column>
      <el-table-column prop="paipanDTO1.month" label="月"> </el-table-column>
      <el-table-column prop="paipanDTO1.day" label="日"> </el-table-column>
      <el-table-column prop="paipanDTO1.hour" label="小时"> </el-table-column>
      <el-table-column prop="paipanDTO1.minute" label="分钟"> </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <div v-if="scope.row.paipanDTO1.sex == 1">男</div>
          <div v-if="scope.row.paipanDTO1.sex == 0">女</div>
        </template>
      </el-table-column>
      <el-table-column label="是否阴历">
        <template slot-scope="scope">
          <div v-if="scope.row.paipanDTO1.islunar == 0">阳历</div>
          <div v-if="scope.row.paipanDTO1.islunar == 1">阴历</div>
        </template>
      </el-table-column>
      <el-table-column label="是否太阳时">
        <template slot-scope="scope">
          <div v-if="scope.row.paipanDTO1.istaiyang == 0">否</div>
          <div v-if="scope.row.paipanDTO1.istaiyang == 1">是</div>
        </template>
      </el-table-column>
      <el-table-column label="是否是闰月">
        <template slot-scope="scope">
          <div v-if="scope.row.paipanDTO1.islunarmonth == 1">是</div>
          <div v-if="scope.row.paipanDTO1.islunarmonth == 2">否</div>
        </template>
      </el-table-column>
      <el-table-column label="测试时间">
        <template slot-scope="scope">
          {{ scope.row.paipanDTO1.createdAt | formatDate('yyyy-M-d H:m:s') }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="edit(scope.$index)" type="text" size="small">
            详情
          </el-button>
        </template>
      </el-table-column> -->
    
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
  </div>
</template>

<script>
import operate from "../../api/api.js";
import updatePage from "./info.vue";
export default {
  components: {
    updatePage
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible: false,
      addPageVisible: false,
      editId: 0,
      tableData: [],
      crumb: ['合婚报告', '列表']
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    searchput() {
      this.getList(1);
    },
    getKeyword() { },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId)
      this.updatePageVisible = true;
    },
    getList(p) {
      let data = {}
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword }
      } else {
        data == { pageIndex: p }
      }
      operate.getHehunList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getHehunList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
  },
};
</script>
<style>
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>