<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
    <el-form-item label="日干">
        <el-select v-model="ruleForm.rigan" placeholder="请选择">
          <el-option
            v-for="item in tiangan"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="ruleForm.type">
          <el-radio :label="'nianzhi'">年支</el-radio>
          <el-radio :label="'yuezhi'">月支</el-radio>
          <el-radio :label="'rizhi'">日支</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.type=='nianzhi'" label="年支">
        <el-select v-model="ruleForm.nianzhi" placeholder="请选择">
          <el-option
            v-for="item in dizhi"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.type=='yuezhi'" label="月支">
        <el-select v-model="ruleForm.yuezhi" placeholder="请选择">
          <el-option
            v-for="item in dizhi"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ruleForm.type=='rizhi'" label="日支">
        <el-select v-model="ruleForm.rizhi" placeholder="请选择">
          <el-option
            v-for="item in dizhi"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="主体标准主">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.zhutiZhu"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="主体标准客">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.zhutiKe"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="主附属标准主">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.zhufuZhu"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="主附属标准客">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.zhufuKe"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="次附属标准主">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.cifuKe"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="次附属标准客">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.cifuKe"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        type:'nianzhi',
        rigan: "",
        nianzhi: "",
        yuezhi: "",
        rizhi: "",
        sex: "1",
        zhutiZhu: "",
        zhutiKe: "",
        zhufuZhu: "",
        zhufuKe: "",
        cifuZhu: "",
        cifuKe: "",
        id:0
      },
      tiangan: [
        {
          value: "甲",
          label: "甲",
        },
        {
          value: "乙",
          label: "乙",
        },
        {
          value: "丙",
          label: "丙",
        },
        {
          value: "丁",
          label: "丁",
        },
        {
          value: "戊",
          label: "戊",
        },
        {
          value: "己",
          label: "己",
        },
        {
          value: "庚",
          label: "庚",
        },
        {
          value: "辛",
          label: "辛",
        },
        {
          value: "壬",
          label: "壬",
        },
        {
          value: "癸",
          label: "癸",
        },
      ],
      dizhi: [
        {
          value: "子",
          label: "子",
        },
        {
          value: "丑",
          label: "丑",
        },
        {
          value: "寅",
          label: "寅",
        },
        {
          value: "卯",
          label: "卯",
        },
        {
          value: "辰",
          label: "辰",
        },
        {
          value: "巳",
          label: "巳",
        },
        {
          value: "午",
          label: "午",
        },
        {
          value: "未",
          label: "未",
        },
        {
          value: "申",
          label: "申",
        },
        {
          value: "酉",
          label: "酉",
        },
        {
          value: "戌",
          label: "戌",
        },
        {
          value: "亥",
          label: "亥",
        },
      ],
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
    };
  },
  methods: {
    submitForm() {
      operate.editBaogao(this.ruleForm, "post").then((response) => {
        if(response.code=='0000'){
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    getInfo(id) {
    operate.getBaogaoInfo(id).then((response) => {
      this.ruleForm = response.data;
      
    });
  },
  },

  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>