<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-select v-model="status" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 10px"
    >
      <div
        style="
          display: flex;
          color: #909399;
          font-size: 14px;
          margin-bottom: 10px;
        "
      >
        <div style="margin-right: 10px">
          总预约数：{{ total }}
        </div>
        <div style="margin-right: 10px">
          待处理：{{ waitCount }}
        </div>
      </div>
      
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column  label="用户">
        <template slot-scope="scope">
          {{ scope.row.userDTO.nickname }}
        </template>
      </el-table-column>
      <el-table-column  label="手机">
        <template slot-scope="scope">
          {{ scope.row.userDTO.phone }}
        </template>
      </el-table-column>
      <el-table-column  label="微信">
        <template slot-scope="scope">
          {{ scope.row.userDTO.wechat }}
        </template>
      </el-table-column>
       <el-table-column  label="预约老师">
        <template slot-scope="scope">
          {{ scope.row.teacherDTO.name }}
        </template>
      </el-table-column>
      <el-table-column prop="points" label="消耗易豆"> </el-table-column>
      <el-table-column label="预约时间">
        <template slot-scope="scope">
          {{scope.row.createdAt|formatDate('yyyy-M-d H:m:s')}}
        </template>
      </el-table-column>
      <el-table-column  label="预约状态">
        <template slot-scope="scope">
         <div v-if="scope.row.status==0">未预约</div>
         <div v-if="scope.row.status==1" style="color:green">已预约</div>
         <div v-if="scope.row.status==2" style="color:red">预约失败</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="yuyue(scope.$index,1)"
            type="text"
            size="small"
            v-if="scope.row.status==0"
          >
            预约成功
          </el-button>
          <el-button
            @click.native.prevent="yuyue(scope.$index,2)"
            type="text"
            size="small"
            v-if="scope.row.status==0"
          >
            预约失败
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
     <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
export default {
  components: {
    updatePage,
  },
  data() {
    return {
       updatePageVisible: false,
      editId: 0,
      loading: false,
      keyword: "",
      status:-1,
      waitCount:0,
      total: 0,
      tableData: [],
      crumb:['预约记录','列表'],
      options: [
      {
          value: -1,
          label: "不限状态",
        },
        {
          value: 0,
          label: "未预约",
        },
        {
          value: 1,
          label: "已预约",
        },
        {
          value: 2,
          label: "预约失败",
        }
      ],
    };
  },
  methods: {
    yuyue(index,status){
      let _info = this.tableData[index];
      operate.updateUserrExpendStatus({id:_info.id,status:status}, "post").then((response) => {
        if(response.code=='0000'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
      });
      this.getWaitList()
    },
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    deleteRow(index, rows) {
      let _info=this.tableData[index]
      operate.deleteUserExpend(_info.id).then((response)=>{
      if(response.code=='0000'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
    searchput() {
     this.getList(1)
    },
    getKeyword() {},
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    getList(p) {
      let data={}
      if(this.keyword.length>0){
        data={ pageIndex: p, name: this.keyword ,type:'teacher'}
      }else{
        data={ pageIndex: p,type:'teacher'}
      }
      if(this.status!=-1){
        data.status=this.status
      }
      operate.getUserExpendList(data).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    },
     getWaitList() {
      let data={}
        data.status=0
        data.type='teacher'
      operate.getUserExpendList(data).then((response) => {
      this.waitCount = response.total;
     });
    },
  },
  mounted() {
    operate.getUserExpendList({ pageIndex: 1,type:'teacher' }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    operate.getUserExpendList({status:0,type:'teacher'}).then((response) => {
      this.waitCount = response.total;
     });
  },
};
</script>