<template>
  <div class="right-content">
    <el-descriptions title="用户详情" :column="2" border>
      <el-descriptions-item label="用户名">{{
        ruleForm.nickname
      }}</el-descriptions-item>
      <el-descriptions-item label="头像">
        <el-image
          style="width: 100px; height: 100px"
          :src="'/api' + ruleForm.headimgurl"
          :fit="fit"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="微信号">{{
        ruleForm.wechat
      }}</el-descriptions-item>
      <el-descriptions-item label="剩余易豆">{{
        ruleForm.points
      }}</el-descriptions-item>
      <el-descriptions-item label="累计充值">{{
        ruleForm.chongMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="可提现">{{
        ruleForm.tiMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="累计返佣">{{
        ruleForm.fanMoneyIn
      }}</el-descriptions-item>
      <el-descriptions-item label="首次登录时间">{{
        ruleForm.createdAt | formatDate("yyyy-M-d H:m:s")
      }}</el-descriptions-item>
      <el-descriptions-item label="上次登录时间">{{
        ruleForm.lastLoginAt | formatDate("yyyy-M-d H:m:s")
      }}</el-descriptions-item>
      <el-descriptions-item label="邀请人">
        <div v-if="ruleForm.fromUserDTO">
          {{ ruleForm.fromUserDTO.nickname }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="电话"
        ><div v-if="ruleForm.phone">
          {{ ruleForm.phone }}
        </div></el-descriptions-item
      >
      <el-descriptions-item label="上级代理">
        <div v-if="ruleForm.superiorUserDTO">
          {{ ruleForm.superiorUserDTO.nickname }}
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="区域">{{
        ruleForm.entCity
      }}</el-descriptions-item>
      <el-descriptions-item label="总充值金额">{{
        ruleForm.chongMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="出生日期">{{
        ruleForm.birthday
      }}</el-descriptions-item>
      <el-descriptions-item label="总充值易豆">{{
        ruleForm.chongPoints
      }}</el-descriptions-item>
      <el-descriptions-item label="企业名称">{{
        ruleForm.entName
      }}</el-descriptions-item>
      <el-descriptions-item label="总消耗易豆">{{
        ruleForm.chongPoints - ruleForm.points
      }}</el-descriptions-item>
      <el-descriptions-item label="所属行业">{{ruleForm.entIndustry}}</el-descriptions-item>
      <el-descriptions-item label="易豆余额">{{ruleForm.points
      }}</el-descriptions-item>
       <el-descriptions-item label="所在地址">{{
        ruleForm.entAddress
      }}</el-descriptions-item>
       <el-descriptions-item label="状态">
        <div v-if="ruleForm.status==1">
         正常
        </div>
        <div v-if="ruleForm.status==0" style="padding:10px 20px;color:#FFFFFF;background-color:#3688E8">
        封禁
        </div>
       </el-descriptions-item>
   <el-divider></el-divider>
      <el-descriptions-item label="排盘次数">{{
        ruleForm.paipanCount
      }}</el-descriptions-item>
       <el-descriptions-item label="生成报告">{{
        ruleForm.baogaoCount
      }}</el-descriptions-item>
       <el-descriptions-item label="进阶课程兑换个数">{{
        ruleForm.courseCount
      }}</el-descriptions-item>

       <el-descriptions-item label="报告扣除易豆">{{
        ruleForm.baogaoPoints
      }}</el-descriptions-item>

       <el-descriptions-item label="进阶课程扣除易豆">{{
        ruleForm.coursePoints
      }}</el-descriptions-item>

       <el-descriptions-item label="咨询导师个数">{{
        ruleForm.teacherNum
      }}</el-descriptions-item>

       <el-descriptions-item label="总咨询次数">{{
        ruleForm.teacherCount
      }}</el-descriptions-item>

       <el-descriptions-item label="基础知识兑换个数">{{
        ruleForm.basicKnowledgeCount
      }}</el-descriptions-item>

       <el-descriptions-item label="咨询扣除易豆">{{
        ruleForm.teacherPoints
      }}</el-descriptions-item>

       <el-descriptions-item label="基础知识扣除易豆">{{
        ruleForm.basicKnowledgePoints
      }}</el-descriptions-item>
       </el-descriptions>
  </div>
</template>
    <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {},
    };
  },
  methods: {},
  mounted() {
    let param = this.$route.params;
    let id = param["id"];
    operate.getUserInfo(id).then((response) => {
      this.ruleForm = response.data;
    });
  },
};
</script>