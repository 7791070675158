<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="备注" prop="money">
        <el-input
          type="textarea"
          :rows="4"
          v-model="ruleForm.note"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" v-if="ruleForm.status==0">
        <el-radio-group v-model="status">
          <el-radio :label="0">待审</el-radio>
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="ruleForm.status==0">
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
      },
      status:0
    };
  },
  props: ['id'],
  created() {
    this.getInfo(this.id);
  },
  watch:{
      id(newval,oldval){
        let loadingInstance=Loading.service({
          text: '数据获取中',
        });
        this.id = newval;
        this.getInfo(newval);
        setTimeout(() => {
          loadingInstance.close();
        }, 2000);
      }
  },
  methods: {
    getInfo(id) {
      operate.getWithdrawalInfo(id).then((response) => {
      this.ruleForm = response.data;
      this.status=this.ruleForm.status
    });
    },
    submitForm() {
      operate.editWithdrawal(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
              this.$emit('closeUpdatePate');
            }, 2000);
        }
      });
    },
  },
  mounted() {
    // let param = this.$route.params;
    // let id = param["id"];
    // operate.getCooperationInfo(id).then((response) => {
    //   this.ruleForm = response.data;
    // });
  },
};
</script>