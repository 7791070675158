<template>
  <div style="height: 100%">
    <div style="width:25%;padding: 7.5px;">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {

  },
};
</script>
<style scoped>

</style>