<template>
  <div class="header-content">
    <div class="l-content">
      <i :class="[isLeft?leftClass:rightClass]" @click="handleAside"></i>
    </div>
    <div class="r-content">
      <el-dropdown trigger="click" size="mini" @command="handleDropDown">
        <span class="el-dropdown-link">
          <img :src="userImg" class="user" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="user">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // 定义属性
  data() {
    return {
      userImg: require("../assets/user.png"),
      isLeft:true,
      leftClass:'el-icon-caret-left',
      rightClass:'el-icon-caret-right'
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleAside(){
      this.isLeft=!this.isLeft
        this.$store.commit('collapsemenu')
    },
    handleDropDown(command){
      console.log(command)
      
        window.location.href="/"
    }
  },
};
</script>

<style lang='scss' scoped>
.header-content {
  height: 60px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 20px;
}
.l-content {
  display: flex;
  align-items: center;
  i {
    margin: 0 20px;
    cursor: pointer;
  }
}
.r-content {
  .user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>