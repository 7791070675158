import { login, editManager } from "@/api/user"
const user = {
    state: {
        token: '',
        name: '',
        roles: []
    },
    mutations: {
        set_token: (state, token) => {
            state.token = token
        },
        set_name: (state, name) => {
            state.name = name
        },
        set_roles: (state, roles) => {
            state.roles = roles
        }
    },
    actions: {
        login({ commit }, data) {
            const { name, password } = data
            return new Promise((resolve, reject) => {
                login({ name: name.trim(), password: password }).then(response => {
                    const { data } = response
                    commit('set_token', data.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getInfo({ commit }, state) {
            return new Promise((resolve, reject) => {
                editManager(state.token).then(response=>{
                    const {data}=response
                    commit('set_name',data.name)
                    commit('set_roles',data.role)
                    resolve(data)
                })
            })
        },

        // user logout
        logout({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('set_token','')
                    resolve()
                    }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}
export default user;