<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="标题" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="ruleForm.type" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="轮播图">
        <el-upload
          class="avatar-uploader"
          action="/api/fileInfo/upload"
          :show-file-list="false"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
          :headers="headers"
        >
          <img
            v-if="ruleForm.image"
            :src="'/api' + ruleForm.image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接" prop="path">
        <el-input v-model="ruleForm.path"></el-input>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-radio-group v-model="ruleForm.status">
        <el-radio :label="0">不开启</el-radio>
        <el-radio :label="1">开启</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        image: "",
        type: "",
        path: "",
        id: 0,
      },
      headers: {
        "X-PD-TOKEN": localStorage.getItem("token"),
      },
      typeList: [
        {
          value: 1,
          label: "首页顶部",
        },
        {
          value: 2,
          label: "首页中间",
        },
        {
          value: "3",
          label: "咨询页",
        },
        {
          value: "4",
          label: "超级个体",
        },
        {
          value: "5",
          label: "案例分析",
        },
      ]
    };
  },
  methods: {
    submitForm() {
      if (
        this.ruleForm.name.length <= 0 ||
        this.ruleForm.type.length <= 0 ||
        this.ruleForm.image.length <= 0
      ) {
        this.$message({
          message: "请完成所有输入项",
          type: "error",
        });
        return;
      }
      operate.editLunbo(this.ruleForm, "post").then((response) => {
        if (response.code == "0000") {
          this.$message.success("成功");
          setTimeout(() => {
            this.$emit("closeUpdatePate");
          }, 2000);
        }
      });
    },
    uploadRemove(file, fileList) {
      this.ruleForm.image = "";
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == "0000") {
        this.ruleForm.image = response.data;
      }
    },

    getInfo(id) {
      operate.getLunboInfo(id).then((response) => {
        this.ruleForm = response.data;
      });
    },
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>