<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/cooperation/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item>
        <el-select v-model="status" placeholder="请选择状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="activationCodePackageId" placeholder="请选择等级">
          <el-option v-for="item in activationCodePackage" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入关键字" clearable size="small" style="width: 240px" v-model="keyword"
          @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;color: #909399;font-size: 14px;margin-bottom: 10px;">
      <div style="margin-right: 10px;">总申请数：{{ statistics.totalCount }}</div>
      <div style="margin-right: 10px;">已通过：{{ statistics.approveCount }}</div>
      <div style="margin-right: 10px;">待处理：{{ statistics.pendingCount }}</div>
      <div>未通过：{{ statistics.rejectCount }}</div>
    </div>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column label="用户昵称" fixed>
        <template slot-scope="scope">
          <div v-if="scope.row.userDTO.nickname"> {{ scope.row.userDTO.nickname }}</div>

        </template>
      </el-table-column>
      <el-table-column label="等级">
        <template slot-scope="scope">
          <div v-if="scope.row.activationCodePackageDTO">
            {{ scope.row.activationCodePackageDTO.name }}
          </div>
          <div v-else>暂无</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="联系人" fixed> </el-table-column>
      <el-table-column prop="mobile" label="手机" fixed> </el-table-column>
      <el-table-column prop="province" label="所在省"> </el-table-column>
      <el-table-column prop="city" label="所在市"> </el-table-column>
      <el-table-column prop="district" label="所在区"> </el-table-column>
      <el-table-column prop="description" label="自我介绍"> </el-table-column>
      <el-table-column prop="activationCodeNum" label="累计分配激活码"> </el-table-column>
      <el-table-column prop="activationCodeNum2" label="已分配激活码"> </el-table-column>
      <el-table-column prop="activationCodeNum1" label="已激活"> </el-table-column>
      <el-table-column label="申请状态">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">待审核</div>
          <div v-if="scope.row.status == 1">审核通过</div>
          <div v-if="scope.row.status == 2">拒绝</div>
          <div v-if="scope.row.status == 3">不合作</div>
        </template>
      </el-table-column>

      <el-table-column label="申请时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="edit(scope.$index)" type="text" size="small">
            详情
          </el-button>
          <el-button @click.native.prevent="decreasecode(scope.$index)" type="text" size="small"
            v-if="scope.row.status == 1">
            减少激活码
          </el-button>
          <el-button @click.native.prevent="dispatchDaili(scope.$index)" type="text" size="small"
            v-if="scope.row.status == 1">
            分派激活码
          </el-button>
          <el-button @click.native.prevent="changeDenji(scope.$index)" type="text" size="small"
            v-if="scope.row.status == 1">
            调整等级
          </el-button>
          <el-button @click.native.prevent="showXiaji(scope.$index)" type="text" size="small"
            v-if="scope.row.status == 1">
            查看下级
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
    <el-dialog title="减少激活码" :visible.sync="decreasecodeVisible" width="80%">
      <decreasecode :id="editId" @closeDecreasecode="closeDecreasecode"></decreasecode>
    </el-dialog>
    <el-dialog title="分派激活码" :visible.sync="dispatchVisible" width="80%">
      <dispatch :id="editId" @closeDispatch="closeDispatch"></dispatch>
    </el-dialog>
    <el-dialog title="调整等级" :visible.sync="dengjiVisible" width="80%">
      <dengji :id="editId" @closeDispatch="closeDengji"></dengji>
    </el-dialog>
    <el-dialog title="下级" :visible.sync="xiajiVisible" width="80%">
      <xiaji :id="editId" @closeXiaji="closeXiaji"></xiaji>
    </el-dialog>
  </div>
</template>

<script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
import dispatch from "./dispatch.vue";
import dengji from "./dengji.vue";
import xiaji from "./xiaji.vue";
import decreasecode from "./decreasecode.vue";
export default {
  name: "productList",
  components: {
    updatePage, dispatch, dengji, xiaji, decreasecode
  },
  data() {
    return {
      updatePageVisible: false,
      dispatchVisible: false,
      decreasecodeVisible: false,
      dengjiVisible: false,
      xiajiVisible: false,
      editId: 0,
      loading: false,
      page: 1,
      keyword: "",
      status: -2,
      activationCodePackageId:0,
      total: 0,
      statistics: {},
      tableData: [],
      crumb: ["合作申请", "列表"],
      activationCodePackage:[
      {
          value: 0,
          label: "不限",
        },
        {
          value: 7,
          label: "1级",
        },
        {
          value: 6,
          label: "2级",
        },
        {
          value: 5,
          label: "3级",
        }, {
          value: 4,
          label: "4级",
        }, {
          value: 3,
          label: "5级",
        }, {
          value: 2,
          label: "6级",
        }, {
          value: 1,
          label: "7级",
        },
      ],
      options: [
        {
          value: -2,
          label: "不限状态",
        },
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "未通过",
        }, {
          value: 3,
          label: "不合作",
        },
      ],
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeDispatch() {
      this.dispatchVisible = false;
    },
    closeDengji() {
      this.dengjiVisible = false;
      this.getList(this.page)
    },
    closeXiaji() {
      this.xiajiVisible = false;
    },
    closeDecreasecode() {
      this.closeDecreasecode = false
    },
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        operate.deleteCooperation(_info.id).then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    searchput(e) {
      this.getList(1)
    },
    getKeyword() { },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    decreasecode(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.decreasecodeVisible = true;
    },
    showXiaji(index) {
      let id = this.tableData[index].userDTO.id;
      this.editId = id;
      this.xiajiVisible = true;
    },
    changeDenji(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.dengjiVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    dispatchDaili(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      this.dispatchVisible = true;
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword };
      } else {
        data = { pageIndex: p };
      }
      if (this.status != -2) {
        data.status = this.status
      }
      if(this.activationCodePackageId){
        data.activationCodePackageId = this.activationCodePackageId
      }
      this.page = p
      operate.getCooperationList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getCooperationList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    operate.getCooperationStatistics({ pageIndex: 1 }).then((response) => {
      this.statistics = response.data;
    });
  },
};
</script>