import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import tab from './modules/tab'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        tab,
        user
      },
})