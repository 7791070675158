<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/lunbo/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="手机号码" prop="name">
        <el-input placeholder="请输入" clearable size="small" style="width: 240px" v-model="keyword" @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="mobile" label="手机"> </el-table-column>
      <el-table-column prop="wechat" label="微信"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949"
            @change="changeStatus(scope.row, scope.$index)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="申请时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
    <el-dialog title="详细信息" :visible.sync="updatePageVisible" width="80%">
      <updatePage :id="editId" @closeUpdatePate="closeUpdatePate"></updatePage>
    </el-dialog>
  </div>
</template>

<script>
import operate from "../../api/api.js";
import updatePage from "./edit.vue";
export default {
  components: {
    updatePage
  },
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      updatePageVisible: false,
      addPageVisible: false,
      editId: 0,
      tableData: [],
      crumb: ["申请会员", "列表"],
    };
  },
  methods: {
    closeUpdatePate() {
      this.updatePageVisible = false;
    },
    closeAddPate() {
      this.addPageVisible = false;
    },
    changeStatus(e) {
      operate.editApplyMember({ id: e.id, status: e.status ? 0 : 1 }, "post").then((response) => {
        if (response.code == '0000') {
          this.$message({
            message: response.msg,
            type: 'success',
            onClose: function () {
              window.location.reload()
            }
          })
        }
      });
    },
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        operate.deleteLunbo(_info.id).then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    searchput(e) {
      this.getList(1)
    },
    getKeyword() { },
    add() {
      this.addPageVisible = true;
    },
    edit(index) {
      let id = this.tableData[index].id;
      this.editId = id;
      console.log(this.editId)
      this.updatePageVisible = true;
      // let id = this.tableData[index].id;
      // let path = this.$route.path + "/edit/" + id;
      // this.$router.push(path);
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, mobile: this.keyword };
      } else {
        data = { pageIndex: p };
      }
      operate.getApplyMember(data).then((response) => {
        this.tableData = response.data;
        this.tableData.forEach(function (item, index) {
          if (item.status == 1) {
            item.isEnable = true;
          } else {
            item.isEnable = false;
          }
        })
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getApplyMember({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.tableData.forEach(function (item, index) {
        if (item.status == 1) {
          item.isEnable = true;
        } else {
          item.isEnable = false;
        }
      })
      this.total = response.total;
    });
  },
};
</script>