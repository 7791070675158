<template>
  <div class="right-content">
    <el-descriptions title="排盘详情" direction="vertical" :column="4" border>
      <el-descriptions-item label="用户名">{{
        ruleForm.name
      }}</el-descriptions-item>
      <el-descriptions-item label="年">{{
        ruleForm.year
      }}</el-descriptions-item>
      <el-descriptions-item label="月">{{
        ruleForm.month
      }}</el-descriptions-item>
      <el-descriptions-item label="日">{{ ruleForm.day }}</el-descriptions-item>
      <el-descriptions-item label="时">{{
        ruleForm.hour
      }}</el-descriptions-item>
      <el-descriptions-item label="分">{{
        ruleForm.minute
      }}</el-descriptions-item>
      <el-descriptions-item label="城市">{{
        ruleForm.city
      }}</el-descriptions-item>
      <el-descriptions-item label="生肖">{{
        ruleForm.animal
      }}</el-descriptions-item>
      <el-descriptions-item label="年干支">{{
        ruleForm.yearganzhi
      }}</el-descriptions-item>
      <el-descriptions-item label="出生上一个节气">{{
        ruleForm.jieqiprev
      }}</el-descriptions-item>
      <el-descriptions-item label="节气名称">{{
        ruleForm.jieqiname
      }}</el-descriptions-item>
      <el-descriptions-item label="节气日期">{{
        ruleForm.date
      }}</el-descriptions-item>
      <el-descriptions-item label="出生下一个节气">{{
        ruleForm.jieqinext
      }}</el-descriptions-item>
      <el-descriptions-item label="八字">{{
        ruleForm.bazi
      }}</el-descriptions-item>
      <el-descriptions-item label="胎元">{{
        ruleForm.taiyuan
      }}</el-descriptions-item>
      <el-descriptions-item label="命宫">{{
        ruleForm.minggong
      }}</el-descriptions-item>
      <el-descriptions-item label="旬空">{{
        ruleForm.xunkong
      }}</el-descriptions-item>
      <el-descriptions-item label="起运时间">{{
        ruleForm.qiyun
      }}</el-descriptions-item>
      <el-descriptions-item label="交运时间">{{
        ruleForm.jiaoyun
      }}</el-descriptions-item>
      <el-descriptions-item label="乾造/坤造">{{
        ruleForm.qiankunzao
      }}</el-descriptions-item>
      <el-descriptions-item label="纳音">{{
        ruleForm.nayin
      }}</el-descriptions-item>
      <el-descriptions-item label="神煞 竖排">{{
        ruleForm.shensha
      }}</el-descriptions-item>
      <el-descriptions-item label="大运">{{
        ruleForm.dayun
      }}</el-descriptions-item>
      <el-descriptions-item label="大运年对应的食神">{{
        ruleForm.shishen
      }}</el-descriptions-item>
      <el-descriptions-item label="大运年对应的干支">{{
        ruleForm.ganzhi
      }}</el-descriptions-item>
      <el-descriptions-item label="大运年对应的岁数">{{
        ruleForm.sui
      }}</el-descriptions-item>
      <el-descriptions-item label="流年">{{
        ruleForm.liunian
      }}</el-descriptions-item>
      <el-descriptions-item label="性别">{{
        ruleForm.sex
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
    <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      ruleForm: {},
    };
  },
  methods: {
    getInfo(id) {
      operate.getHehunInfo(id).then((response) => {
        this.ruleForm = response.data;
      });
    },
  },
  props: ["id"],
  created() {
    this.getInfo(this.id);
  },
  watch: {
    id(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.id = newval;
      this.getInfo(newval);
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
};
</script>