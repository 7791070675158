<template>
  <div class="right-content">
    <Breadcrumb :crumb="crumb" addpath="/order/add" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item prop="date">
        <el-date-picker v-model="start_date" type="date" value-format="yyyy-MM-dd" placeholder="请选择开始时间">
        </el-date-picker>
        <span> - </span>
        <el-date-picker class="input-width" v-model="end_date" type="date" value-format="yyyy-MM-dd"
          placeholder="请选择结束时间">
        </el-date-picker>
        <el-select v-model="type" placeholder="请选择类型">
          <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input placeholder="请输入关键字" clearable size="small" style="width: 240px" v-model="keyword"
          @change="getKeyword">
          <el-button slot="append" class="searchbtn" @click="searchput">搜索</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
      <div style="
          display: flex;
          color: #909399;
          font-size: 14px;
          margin-bottom: 10px;
        " n>
        <div style="margin-right: 10px">
          总单数：{{ statistics.totalCount }}
        </div>
        <div style="margin-right: 10px">
          总消耗易豆：{{ statistics.points }}
        </div>
      </div>
      <div></div>
    </div>
    <el-table :data="tableData" style="width: 100%" max-height="550" v-loading="loading">
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column label="用户">
        <template slot-scope="scope">
          {{ scope.row.userDTO.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="消费内容"> </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="scope">
          <div v-if="scope.row.type == 'teacher'">老师预约</div>
          <div v-if="scope.row.type == 'course'">进阶学习</div>
          <div v-if="scope.row.type == 'basicknowledge'">基础知识</div>
          <div v-if="scope.row.type == '排盘'">排盘解密</div>
        </template>
      </el-table-column>
      <el-table-column prop="points" label="消耗易豆"> </el-table-column>
      <el-table-column prop="remainingPoints" label="剩余易豆"> </el-table-column>
      <el-table-column label="消费时间">
        <template slot-scope="scope">
          {{ scope.row.createdAt | formatDate("yyyy-M-d H:m:s") }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="getList"></el-pagination>
  </div>
</template>

<script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      type: '',
      total: 0,
      start_date: "",
      end_date: "",
      tableData: [],
      statistics: {},
      crumb: ["消费记录", "列表"],
      types: [
        {
          value: '',
          label: '不限'
        },
        {
          value: 'teacher',
          label: '老师预约'
        },
        {
          value: 'basicknowledge',
          label: '基础知识'
        },
        {
          value: 'course',
          label: '进阶学习'
        },
        {
          value: 'paipan',
          label: '排盘解密'
        },
      ]
    };
  },
  methods: {
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        operate.deleteUserExpend(_info.id).then((response) => {
          if (response.code == "0000") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    searchput() {
      this.getList(1);
    },
    getKeyword() { },
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/edit/" + id;
      this.$router.push(path);
    },
    getList(p) {
      let data = {};
      if (this.keyword.length > 0) {
        data = { pageIndex: p, name: this.keyword };
      } else {
        data = { pageIndex: p };
      }
      if (this.type) {
        data.type = this.type
      }
      if (this.start_date) {
        let createStartAt = new Date(this.start_date).valueOf();
        data.createStartAt = createStartAt;
      }
      if (this.end_date) {
        let createEndAt = new Date(this.end_date).valueOf();
        data.createEndAt = createEndAt;
      }
      operate.getUserExpendList(data).then((response) => {
        this.tableData = response.data;
        this.total = response.total;
      });
    },
  },
  mounted() {
    operate.getUserExpendList({ pageIndex: 1 }).then((response) => {
      this.tableData = response.data;
      this.total = response.total;
    });
    operate.getUserExpendStatistics({ pageIndex: 1 }).then((response) => {
      this.statistics = response.data;
    });
  },
};
</script>